@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* button {
    @apply border py-2 px-4 bg-teal-600 text-white rounded-md hover:bg-teal-800;
  } */

  /* .formPetition {
    @apply gap-y-4 sm:gap-y-2 md:grid-cols-2;
    display: grid;
    grid-gap: 8px;
    grid-template-areas:
      "first_name_label first_name"
      "last_name_label last_name"
      "email_label email"
      / "phone_number_label phone_number"
      "address_label address"
      "postal_code_label postal_code"
      "city_label city"
      "country_label country" /
      "button button";
  }

  .formPetition > input, .formPetition > select {
    @apply border border-black rounded-md focus:ring focus:ring-blue-200 focus:ring-opacity-25;
  }

  .formPetition > button {
    grid-area: button;
    border: 1px solid #111111;
  }

  @media (max-width: 400px) {
    .formPetition {
      grid-template-columns: auto;
      grid-template-areas:
        "first_name"
        "last_name"
        "email"
        "city"
        "button";
    }
  } */
}